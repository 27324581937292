import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { SHHighlight } from '@libs/sortinghat';
import { HighlightsProps } from '.';
import { useDogxContext } from '@libs/dogx';
interface CardTopic extends SHHighlight {
  class?: string;
  badge?: string;
}
export const useHubHighlights = ({
  topics,
  className,
  hideFeaturedTopics,
}: HighlightsProps) => {
  const [topicList, setTopicList] = useState<CardTopic[]>([]);
  const { getExperimentVariant } = useDogxContext();
  const displayScoreBadge =
    getExperimentVariant('dx-cp-score-badge') === 'display';

  useEffect(() => {
    if (topics && topics.length > 0) {
      const list: CardTopic[] = [];

      for (const topic of topics) {
        const featuredTopic = topic?.title && !topic?.description;
        const isScore = topic?.title === 'Score recomendado';

        if (!displayScoreBadge && isScore) continue;
        if (hideFeaturedTopics && featuredTopic) continue;

        const topicClass = classNames('hub-card-highlights', className, {
          'hub-card-highlights--featured-topic': featuredTopic,
        });

        list.push({
          ...topic,
          class: topicClass,
          badge: setBadge(topic),
        });
      }

      setTopicList(list);
    }
  }, [topics, className]);

  function setBadge(topic: SHHighlight) {
    const score = Number(topic.description);

    if (topic.title !== 'Score recomendado' || isNaN(score)) return undefined;

    const badgeClass = classNames('hub-card-highlights__badge ', {
      'hub-card-highlights__badge--orange': score < 550,
      'hub-card-highlights__badge--yellow': score >= 550 && score < 700,
      'hub-card-highlights__badge--green': score >= 700 && score < 800,
      'hub-card-highlights__badge--dark-green': score >= 800,
    });

    return badgeClass;
  }

  return {
    topicList,
  };
};
