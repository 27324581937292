import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';

import { delay } from '@libs/utils/helpers/delay';
import { isIOS } from '@libs/utils/helpers/mobileOS';
import { useStoryblokDatasource } from '@libs/storyblok';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';

import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import {
  isUserFromWebView,
  redirectInApp,
} from '@common/utils/webviewComunication';

import { useApiFirebolt } from '@hub/apis/firebolt/useApiFirebolt';

import { MatchMakerDecision } from '@hub/types/matchmaker';

export const useMMRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    productClickedEvent,
    redirectRequestedEvent,
    proposalStartedEvent,
    elementClickedEvent,
  } = useTracking();
  const { sendSoftProposal } = useApiFirebolt();
  const { user } = useUserContext();
  const [cookies] = useCookies(['app_version']);
  const { getDatasource } = useStoryblokDatasource();

  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const [redirectModal, setRedirectModal] = useState<{
    isOpen: boolean;
    name: string;
    image: string;
  }>({ isOpen: false, name: '', image: '' });

  const handleMatchMakerRedirect = async (
    offer: MatchMakerDecision,
    position: number
  ) => {
    setLoadingRedirect(true);

    const { requestCardUrl, productName, partner, category, uuid, slug } =
      offer;

    setRedirectModal({
      isOpen: true,
      name: partner,
      image: offer?.imageUrl,
    });

    const proposal = await getProposalFromFireboltSoft({ offer });
    const fireboltId = proposal?.firebolt_id || '';
    const safeLocation = location || pathname;

    const url = await getUrl(offer, fireboltId);

    if (!url) return;

    const productClickedEventPayload = {
      product: {
        brand: partner,
        category: category,
        location: safeLocation,
        name: productName,
        position,
        productId: slug,
        text: `${productName} - ${category}`,
      },
    };

    redirectRequestedEvent({
      fireboltId,
      offerSlug: uuid,
      originPath: window.location.pathname,
      redirectURL: url,
    });

    productClickedEvent({ ...productClickedEventPayload, url });
    elementClickedEvent({
      elementType: 'button',
      location: pathname,
      name: `${location} - 'Pedir cartão'}`,
    });
    await sendEventProposalStarted(offer, url, fireboltId);
    await delay(2000);
    redirect(requestCardUrl);
    setRedirectModal({ isOpen: false, name: '', image: '' });
    setLoadingRedirect(false);
  };

  async function getUrl(offer: MatchMakerDecision, fireboltId: string) {
    const url = offer?.requestCardUrl;

    if (url) {
      const completedUrl = await fillUrlParameters(url, fireboltId);
      return completedUrl;
    }
  }

  async function fillUrlParameters(url: string, fireboltId: string) {
    const startSourceUrl = window?.location.href;
    const params = {
      fireboltId,
      sessionId: getSessionID(),
      deliveryId: getDeliveryIdParam({ startSourceUrl }),
      siteId:
        (await getSiteIdParam({
          marketplace: 'cp',
          startSourceUrl,
          getDatasource,
        })) || '',
    };
    const completedUrl = url
      .replace('(firebolt_id)', params.fireboltId)
      .replace('(af_siteid)', params.siteId);

    return completedUrl;
  }

  type FireboltSkipperParams = {
    offer: MatchMakerDecision;
    additionalItem?: any;
    additionalMetadata?: any;
  };
  async function getProposalFromFireboltSoft({
    offer,
    additionalItem = {},
    additionalMetadata = {},
  }: FireboltSkipperParams) {
    if (!offer) return null;

    const isBBCard = offer?.partner === 'bancodobrasil';
    if (isBBCard) return null;

    const item = {
      cpf: user?.document,
      full_name: user?.full_name,
      email: user?.email,
      main_phone: user?.phone,
      date_of_birth: user?.birthdate,
      choosen_card: offer?.slug,
      issuer: offer?.partner,
      ...additionalItem,
    };

    const metadata = {
      start_source_url: window?.location.href,
      user_agent: window.navigator.userAgent,
      anonymousId: getAnonymousID(),
      sessionId: getSessionID(),
      fbCookies: {
        fcbId: getFbAvailableCookies().fbc,
        fbpId: getFbAvailableCookies().fbp,
      },
      gaCookies: {
        gaId: getGAAvailableCookies().ga,
      },
      ...additionalMetadata,
    };
    const proposalResponse = await sendSoftProposal.send(offer?.partner, {
      item,
      metadata,
    });

    return proposalResponse;
  }

  async function sendEventProposalStarted(
    offer: MatchMakerDecision,
    redirectUrl: string,
    fireboltId: string
  ) {
    if (!offer) return;

    const payload = {
      initialSlug: offer?.slug,
      finalSlug: offer?.slug,
      category: offer?.category,
      redirectUrl,
      fireboltId,
    };
    await proposalStartedEvent(payload);
  }

  function redirect(url: string) {
    if (isUserFromWebView() && cookies.app_version) return redirectInApp(url);
    if (isIOS()) return window.open(url, '_top');
    return window.open(url, '_blank');
  }

  return {
    loadingRedirect,
    redirectModal,
    handleMatchMakerRedirect,
  };
};
