import { MatchMakerDecision } from '@hub/types/matchmaker';
import './styles.scss';
import { Button, Text } from '@consumidor-positivo/aurora';
import iconTrash from '@hub/assets/trash.svg';
import classNames from 'classnames';
import { RedirectModal } from '@hub/components/RedirectModal';
import { createPortal } from 'react-dom';
import { useMMRedirect } from '@hub/hooks/useMMRedirect';

interface FavoriteOfferProps {
  offer: MatchMakerDecision;
  handleRemove: (offer: MatchMakerDecision) => void;
  position: number;
}
export const FavoriteOffer = ({
  offer,
  position,
  handleRemove,
}: FavoriteOfferProps) => {
  const { handleMatchMakerRedirect, loadingRedirect, redirectModal } =
    useMMRedirect();

  const approvalText = () => {
    switch (offer?.approvalChance) {
      case 'high':
        return 'alta';
      case 'medium':
        return 'média';
      case 'low':
        return 'baixa';
      default:
        return '';
    }
  };

  const handleClick = async () => {
    await handleMatchMakerRedirect(offer, position);
  };

  return (
    <div className="favorite-offer">
      <div className="favorite-offer__header">
        <img
          className="favorite-offer__header__img-credit"
          src={offer?.imageUrl}
          alt={offer?.productName}
        />
        <img
          className="favorite-offer__header__img-trash"
          src={iconTrash}
          alt="Uma lixeira azul"
          onClick={() => handleRemove(offer)}
        />
        <Text as="h4" variant="heading-micro" weight="bold">
          {offer?.productName}
        </Text>
      </div>
      <div
        className={classNames('favorite-offer__badge', {
          'favorite-offer__badge--hight': offer?.approvalChance === 'high',
          'favorite-offer__badge--medium': offer?.approvalChance === 'medium',
          'favorite-offer__badge--low': offer?.approvalChance === 'low',
        })}
      >
        <Text as="p" variant="body-small" weight="semibold">
          chance <strong>{approvalText()}</strong> de aprovação
        </Text>
      </div>
      <div className="favorite-offer__buttons">
        <Button expand="x" onClick={handleClick} loading={loadingRedirect}>
          Pedir cartão
        </Button>
      </div>
      {createPortal(
        <RedirectModal
          isOpen={redirectModal.isOpen}
          partnerName={redirectModal.name}
          offerImage={redirectModal.image}
        />,
        document.body
      )}
    </div>
  );
};
