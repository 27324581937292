import useConfirmDialog from '@common/components/ConfirmDialog/hook';
import { StorageMap } from '@common/constants/StorageMap';
import { getFromStorage, setOnStorage } from '@common/utils/handleStorage';
import { MatchMakerDecision } from '@hub/types/matchmaker';
import { useEffect, useState } from 'react';

export const useMyFavorites = () => {
  const [offers, setOffers] = useState<MatchMakerDecision[]>([]);
  const { ConfirmDialog, confirm } = useConfirmDialog();

  useEffect(() => {
    setOffers(getFavoritesFromStorage());
  }, []);

  function getFavoritesFromStorage() {
    return getFromStorage<[]>(StorageMap.FavoriteOffers) || [];
  }

  async function handleRemove(offer: MatchMakerDecision) {
    const result = await confirm({
      title: 'Remover favorito',
      message: 'Tem certeza que deseja remover este cartão?',
    });

    console.log(result);

    if (result) {
      const updatedFavorites = offers.filter((fav) => fav.slug !== offer.slug);
      setOffers(updatedFavorites);
      setOnStorage('local', StorageMap.FavoriteOffers, updatedFavorites);
    }
  }

  return {
    offers,
    handleRemove,
    ConfirmDialog,
  };
};
