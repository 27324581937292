export function useStoryblokDatasource() {
  const getDatasource = async (slug: string, page = 1, perPage = 25) => {
    try {
      const token = 'mMivpQJY0SZRRQynhEs69Qtt';
      const apiUrl = `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=${slug}&token=${token}&page=${page}&per_page=${perPage}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      return data?.datasource_entries;
    } catch (error) {
      console.error('SB datasource api fetch error', error);
    }
  };

  return {
    getDatasource,
  };
}
