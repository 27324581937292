import { useStoryblokDatasource } from '@libs/storyblok';

interface Issuer {
  value: string;
  name: string;
}

let issuersCache: Issuer[] = [];

export const getIssuerFriendlyName = async (
  partnerId: string
): Promise<string | null> => {
  try {
    if (issuersCache.length === 0) {
      const { getDatasource } = useStoryblokDatasource();
      issuersCache = await getDatasource('issuers', 1, 1000);
    }

    const matchedIssuer = issuersCache.find(
      (issuer: Issuer) => issuer.value === partnerId
    );

    return matchedIssuer ? matchedIssuer.name : null;
  } catch (error) {
    console.error('Erro ao buscar os emissores:', error);
    return null;
  }
};
